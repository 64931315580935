import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AttachmentDocument } from '@shared/interfaces/common.interface';
import { getFormattedUrl } from '@shared/utils/common-utils';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-attachment-list',
  standalone: true,
  imports: [TooltipModule],
  templateUrl: './attachment-list.component.html',
  styleUrl: './attachment-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentListComponent {
  @Input() url? = '';
  @Input() attachments: AttachmentDocument[] | null = [];

  getFormattedUrl = getFormattedUrl;
}
