<div class="attachment-container">
  <div class="d-flex g-8">
    <div><i class="pi pi-folder-open"></i></div>
    <div class="title">Attachment and Links</div>
  </div>
  <div>
    <div class="attachment-item">
      @if (attachments) {
        @for (doc of attachments; track doc.id) {
          <div class="d-flex align-items-center g-8">
            <i class="pi pi-paperclip mr-2"></i>
            <a
              class="link"
              [href]="doc.document"
              target="_blank"
              [pTooltip]="doc.document"
              tooltipPosition="top"
              rel="noopener"
              >{{ doc.name }}</a
            >
          </div>
        }
      }
      @if (url) {
        <div class="d-flex align-items-center g-8">
          <i class="pi pi-link"></i>
          <a
            class="link"
            [href]="getFormattedUrl(url)"
            target="_blank"
            [pTooltip]="url"
            tooltipPosition="top"
            rel="noopener"
            >{{ url }}</a
          >
        </div>
      }
    </div>
  </div>
</div>
